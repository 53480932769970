import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Clear } from '@mui/icons-material'
import {
	createUserTeamRequest,
	setUserTeamProfileRequest,
} from 'redux/user/reducer'
import { selectUser } from 'redux/user/selectors'

import { Button, H2, HelperText, Input } from 'share'

import buttonStyles from 'constants/buttonSettings'

import * as Yup from 'yup'
import { ITeam } from 'redux/user/types'
import { sendGtagEvent } from 'utils/userUtils'

const { PRIMARY, SECONDARY, MEDIUM_H, FULL_W, CONTAINED, MARGIN_LEFT } =
	buttonStyles

type IFormNameTypes = 'name' | 'address' | 'representative'

interface FormMapper {
	name: IFormNameTypes
	label: string
	type: string
}

const companyProfileSchema = Yup.object().shape({
	name: Yup.string()
		.required('Company legal name is required')
		.max(100, 'Company length must not exceed 100 characters'),
	address: Yup.string()
		.required('Address is required')
		.max(200, 'Address length must not exceed 200 characters'),
	representative: Yup.string()
		.required('Company Representative is required')
		.max(100, 'Company Representative length must not exceed 100 characters'),
})

const FORM_MAPPER: FormMapper[] = [
	{
		name: 'name',
		label: 'Company legal name',
		type: 'text',
	},
	{
		name: 'address',
		label: 'Address',
		type: 'text',
	},
	{
		name: 'representative',
		label: 'Company Representative',
		type: 'text',
	},
]

interface ICompanyProfileFormModal {
	onClose: () => void
	isCreateMode?: boolean
}

const defaultProps = {
	isCreateMode: false,
}

const CompanyProfileFormModal: React.FC<ICompanyProfileFormModal> = ({
	onClose,
	isCreateMode = false,
}) => {
	const dispatch = useDispatch()

	const {
		user,
		isSetUserTeamProfileLoading,
		isCreateUserTeamLoading,
	} = useSelector(selectUser)

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm<ITeam>({
		mode: 'onChange',
		defaultValues: {
			name: isCreateMode ? '' : user.team?.name ?? '',
			address: isCreateMode ? '' : user.team?.address ?? '',
			representative: `${user.name} ${user.surname}`,
		},
		resolver: yupResolver(companyProfileSchema),
	})

	const onSubmit: SubmitHandler<ITeam> = (formData) => {
		const request = isCreateMode
			? createUserTeamRequest
			: setUserTeamProfileRequest

		dispatch(request(formData))

		onClose()

		if (!user.team?.name && !isCreateMode) {
			sendGtagEvent('company_info_added')
		}
	}

	useEffect(() => {
		if (isCreateMode) {
			return
		}

		reset({
			name: user.team?.name ?? '',
			address: user.team?.address ?? '',
			representative: `${user.name} ${user.surname}`,
		})
	}, [user.team])

	return (
		<>
			<H2 className="flex align-items-center text-semi-bold justify-center">
				{isCreateMode
					? 'Create company'
					: 'Edit company information'}
				<IconButton
					onClick={onClose}
					className="close"
				>
					<Clear fontSize="large" />
				</IconButton>
			</H2>

			<form className="flex-column" onSubmit={handleSubmit(onSubmit)}>
				<div className="form__wrapper-inputs">
					{!user.team?.name ? (
						<div className="grey-box flex-1 padding m-b-half">
							<p>
								In order to send invitations, you must first add
								information about the company.
							</p>
						</div>
					) : null}

					{FORM_MAPPER.map((input) => (
						<Fragment key={input.name}>
							<Controller
								name={input.name}
								control={control}
								defaultValue=""
								render={({ field: { onChange, value } }) =>
									<Input
										label={input.label}
										type={input.type}
										value={value as string}
										onChange={onChange}
									/>
								}
							/>
							<HelperText
								className={
									errors[input.name] && !isValid ? '' : 'invisible'
								}
							>
								{errors[input.name]?.message || 'invisible'}
							</HelperText>
						</Fragment>
					))}
				</div>

				<div className="form__buttons m-t-half">
					<Button
						type="button"
						buttonStyle={`${SECONDARY} ${MEDIUM_H} ${FULL_W}`}
						variant={CONTAINED}
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						buttonStyle={`${PRIMARY} ${FULL_W} ${MEDIUM_H} ${MARGIN_LEFT}`}
						variant={CONTAINED}
						disabled={!isValid}
					>
						{isCreateMode ? 'Create' : 'Save'}
					</Button>
				</div>

				{isSetUserTeamProfileLoading || isCreateUserTeamLoading
					? <span className="loader" />
					: null}
			</form>
		</>
	)
}

CompanyProfileFormModal.defaultProps = defaultProps

export default CompanyProfileFormModal
