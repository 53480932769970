import CreateInvoice from 'components/CreateInvoice'
import paths from 'navigation/paths'
import React from 'react'
import { Link } from 'react-router-dom'

import { Logo } from 'share'

import Nav from './Nav'
import UserMenu from './UserMenu'

import './index.scss'

const Header = () => (
		<header className="header">
			<div className="header__content">
				<div className="logo__wrapper">
					<Link to={paths.invoices}>
						<Logo logoStyle="" width={119}/>
					</Link>
				</div>
				<div className="header__main">
					<Nav/>

					<div
						className="header__right-bar flex align-items-center space-between"
					>
						<CreateInvoice/>

						<UserMenu/>
					</div>
				</div>
			</div>
		</header>
	)

export default Header
