const processEnv = process.env

export default {
	services: {
		sendRegistration: 'api/auth/register',
		signIn: 'api/auth/login',
		verification: 'api/auth/verifyemail',
		changePassword: 'api/auth/change-password',
		forgotPassword: 'api/auth/forgot-password',
		updateUser: 'api/auth/update-contractor',
		otpValidate: 'api/auth/otp/validate',
		otpVerify: 'api/auth/otp/verify',
		otpGenerate: 'api/auth/otp/generate',
		otpDisable: 'api/auth/otp/disable',
		sso: 'api/auth/sso',

		getUsers: 'api/user/get-my-users',
		getMembers: 'api/user/get-my-members',
		getInvoices: 'api/user/get-my-invoices',
		getInvoicesReadyToPay: 'api/user/get-my-invoices-ready-to-pay',
		getInvoiceReports: 'api/user/get-my-invoice-reports',
		connectNearWallet: 'api/user/connect-near-wallet',
		joinUsers: 'api/user/join',
		inviteUsers: 'api/user/invite',
		inviteMembers: 'api/user/invite-members',
		triggerTeamMemberRole: 'api/user/trigger-team-member-role',
		deleteTeamMember: 'api/user/delete-team-member',
		resendUserInvite: 'api/user/resend-invite',
		revokeUserInvite: 'api/user/revoke-invite',
		getUser: 'api/user/get-user',
		getTeamDefaultApprovers: 'api/user/get-my-team-default-approvers',
		getMyUser: 'api/user/get-my-user',
		deleteMyUser: 'api/user/delete-my-user',
		getUserWalletAcceptedTokens: 'api/user/get-my-wallet-accepted-tokens',
		updateTeamProfile: 'api/user/update-team-profile',
		createTeam: 'api/user/create-team',
		updateTeamSubscription: 'api/user/update-team-subscription',
		updateTeamApprovalSettings: 'api/user/update-team-approval-settings',
		updateUserProfile: 'api/user/update-profile',
		updateUserTeam: 'api/user/update-team',
		deleteUserAvatar: 'api/user/delete-avatar',
		deleteTeamLogo: 'api/user/delete-team-logo',
		uploadUserAvatar: 'api/user/upload-avatar',
		uploadTeamLogo: 'api/user/upload-team-logo',
		userChangePassword: 'api/user/change-password',
		updateUserAccountId: 'api/user/update-user-account',
		updateUserWalletAcceptedToken: 'api/user/update-user-wallet-accepted-token',
		discardWelcomeSteps: 'api/user/discard-welcome-steps',
		getWalletById: 'api/user/get-wallet/',
		getWallets: 'api/user/get-wallets',
		updateWallet: 'api/user/update-wallet/',
		deleteWallet: 'api/user/delete-wallet/',

		getInvoice: 'api/invoice/get-invoice',
		getInvoicesForCheckout: 'api/invoice/get-checkout-invoices',
		getInvoiceReviews: 'api/invoice/get-invoice-reviews',
		getInvoiceChangelogs: 'api/invoice/get-invoice-changelogs',
		createInvoice: 'api/invoice/create-invoice',
		updateInvoice: 'api/invoice/update-invoice',
		updateInvoicesReadyToPay: 'api/invoice/update-invoices-ready-to-pay',
		updateInvoicesAccount: 'api/invoice/update-invoices-account',
		deleteInvoice: 'api/invoice/delete-invoice',
		downloadInvoicePdf: 'api/invoice/export-invoice/pdf',
		cloneInvoice: 'api/invoice/clone-invoice',
		archiveInvoice: 'api/invoice/archive-invoice',
		reviewInvoice: 'api/invoice/review-invoice',
		prepareInvoiceForPayment: 'api/invoice/prepare-invoice-for-payment',
		withdraw: 'api/invoice/approve-invoice-withdrawal',
		searchTag: 'api/invoice/tag/search',
		createTag: 'api/invoice/tag/create',
		updateTag: 'api/invoice/tag/update',
		deleteTag: 'api/invoice/tag/delete',
		uploadInvoiceDocument: 'api/invoice/upload-document',
		deleteInvoiceDocument: 'api/invoice/delete-document',
		downloadInvoiceDocument: 'api/invoice/download-document',
	},
	env: {
		REACT_APP_BASE_URL: processEnv.REACT_APP_BASE_URL as string,
		REACT_APP_ENVIRONMENT: processEnv.REACT_APP_ENVIRONMENT as string,

		REACT_APP_NEAR_NETWORK: processEnv.REACT_APP_NEAR_NETWORK as string,
		REACT_APP_NEAR_CONTRACT: processEnv.REACT_APP_NEAR_CONTRACT as string,
		REACT_APP_TRANSACTIONS_SITE:
			processEnv.REACT_APP_TRANSACTIONS_SITE as string,
		REACT_APP_CREATE_NEAR_WALLET_SITE:
			processEnv.REACT_APP_CREATE_NEAR_WALLET_SITE as string,

		REACT_APP_NEAR_TOKEN: processEnv.REACT_APP_NEAR_TOKEN as string,
		REACT_APP_NEAR_USDC_TOKEN: processEnv.REACT_APP_NEAR_USDC_TOKEN as string,
		REACT_APP_NEAR_USDT_TOKEN: processEnv.REACT_APP_NEAR_USDT_TOKEN as string,
		REACT_APP_NEAR_USDTT_TOKEN: processEnv.REACT_APP_NEAR_USDTT_TOKEN as string,

		REACT_APP_ETH_CONTRACT: processEnv.REACT_APP_ETH_CONTRACT as string,
		REACT_APP_ETH_TRANSACTIONS_SITE:
			processEnv.REACT_APP_ETH_TRANSACTIONS_SITE as string,

		REACT_APP_ETH_TOKEN: processEnv.REACT_APP_ETH_TOKEN as string,
		REACT_APP_ETH_USDC_TOKEN: processEnv.REACT_APP_ETH_USDC_TOKEN as string,
		REACT_APP_ETH_USDT_TOKEN: processEnv.REACT_APP_ETH_USDT_TOKEN as string,
		REACT_APP_ETH_BUSD_TOKEN: processEnv.REACT_APP_ETH_BUSD_TOKEN as string,
		REACT_APP_ETH_GUSD_TOKEN: processEnv.REACT_APP_ETH_GUSD_TOKEN as string,

		REACT_APP_ETH_CHAIN_ID: processEnv.REACT_APP_ETH_CHAIN_ID as string,
		REACT_APP_POLYGON_CHAIN_ID: processEnv.REACT_APP_POLYGON_CHAIN_ID as string,
		REACT_APP_BSC_CHAIN_ID: processEnv.REACT_APP_BSC_CHAIN_ID as string,

		REACT_APP_TRON_CONTRACT: processEnv.REACT_APP_TRON_CONTRACT as string,
		REACT_APP_TRON_TRANSACTIONS_SITE:
			processEnv.REACT_APP_TRON_TRANSACTIONS_SITE as string,

		REACT_APP_BSC_CONTRACT: processEnv.REACT_APP_BSC_CONTRACT as string,
		REACT_APP_BSC_TRANSACTIONS_SITE:
			processEnv.REACT_APP_BSC_TRANSACTIONS_SITE as string,

		REACT_APP_BSC_USDT_TOKEN:
			processEnv.REACT_APP_BSC_USDT_TOKEN as string,
		REACT_APP_BSC_USDC_TOKEN:
			processEnv.REACT_APP_BSC_USDC_TOKEN as string,
		REACT_APP_BSC_BUSD_TOKEN:
			processEnv.REACT_APP_BSC_BUSD_TOKEN as string,

		REACT_APP_POLYGON_CONTRACT: processEnv.REACT_APP_POLYGON_CONTRACT as string,
		REACT_APP_POLYGON_TRANSACTIONS_SITE:
			processEnv.REACT_APP_POLYGON_TRANSACTIONS_SITE as string,

		REACT_APP_POLYGON_USDT_TOKEN:
			processEnv.REACT_APP_POLYGON_USDT_TOKEN as string,
		REACT_APP_POLYGON_USDC_TOKEN:
			processEnv.REACT_APP_POLYGON_USDC_TOKEN as string,
		REACT_APP_POLYGON_BUSD_TOKEN:
			processEnv.REACT_APP_POLYGON_BUSD_TOKEN as string,

		REACT_APP_TRON_TOKEN: processEnv.REACT_APP_TRON_TOKEN as string,
		REACT_APP_TRON_USDT_TOKEN: processEnv.REACT_APP_TRON_USDT_TOKEN as string,

		REACT_APP_GG_APP_ID: processEnv.REACT_APP_GG_APP_ID as string,
		REACT_APP_LINKEDIN_APP_SECRET:
			processEnv.REACT_APP_LINKEDIN_APP_SECRET as string,
		REACT_APP_LINKEDIN_APP_ID: processEnv.REACT_APP_LINKEDIN_APP_ID as string,

		REACT_APP_SWAPIN_API_HOST: processEnv.REACT_APP_SWAPIN_API_HOST as string,
		REACT_APP_SWAPIN_API_KEY: processEnv.REACT_APP_SWAPIN_API_KEY as string,
	},
}
