import React, { useCallback, useEffect, useState } from 'react'
import { ArrowForward, Avatar, Button, Modal, Popover } from 'share'
import { getTeamTitle, getUserName, sendGtagEvent } from 'utils/userUtils'
import { RoleEnumType } from 'redux/user/types'
import {
	AddOutlined,
	CheckOutlined,
	Error,
	Logout,
	Settings,
} from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import paths from 'navigation/paths'
import buttonStyles from 'constants/buttonSettings'
import { onCloseNotification } from 'redux/notification/reducer'
import { selectUser } from 'redux/user/selectors'
import { useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { logoutRequest } from 'redux/logout/reducer'
import { setUserProfileRequest, setUserTeamRequest } from 'redux/user/reducer'
import { selectLogout } from 'redux/logout/selectors'
import CompanyProfileFormModal from 'components/CompanyProfileFormModal'
import { resetWalletAccount } from 'redux/walletAccount/reducer'

import UserMenuLink from '../UserMenuLink'

const { TEXT } = buttonStyles

const UserMenu = () => {
	const dispatch = useDispatch()

	const { pathname } = useLocation()

	const { user, userTeams } = useSelector(selectUser)
	const { isLogoutCompletedSuccess } = useSelector(selectLogout)

	const [isPopUpOpen, setIsPopUpOpen] = useState(false)

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const [isModalOpen, setIsModalOpen] = useState(false)

	const userName = getUserName(user)
	const hasUserName = userName?.trim() !== ''
	const hasCompanyName = user.role === RoleEnumType.COMPANY && user.team?.name?.trim() !== ''
	const hasUserOrCompanyName = hasUserName || hasCompanyName

	const onOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)

		dispatch(onCloseNotification())

		setIsPopUpOpen(true)
	}

	const onClosePopover = useCallback(() => {
		setIsPopUpOpen(false)
		setAnchorEl(null)
	}, [])

	const onLogout = () => {
		dispatch(resetWalletAccount())
		dispatch(logoutRequest())
	}

	const onRoleChange = (event: React.MouseEvent<HTMLElement>, newRole: string) => {
		if (!newRole || newRole === user.role) {
			return;
		}

		dispatch(setUserProfileRequest({
			name: user.name,
			surname: user.surname,
			email: user.email,
			address: user.address ?? '',
			role: newRole,
		}))

		sendGtagEvent('role_change', { role: newRole })
	}

	const onTeamChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
		if (!value || value === user.team?.id) {
			return;
		}

		dispatch(setUserTeamRequest({
			id: value,
		}))
	}

	const onCreateCompany = () => {
		setIsModalOpen(true)
	}

	const closeModal = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		if (isLogoutCompletedSuccess) {
			localStorage.clear()

			window.location.pathname = paths.signIn
		}
	}, [isLogoutCompletedSuccess])

	return (
		<>
			<Button
				type="button"
				variant={TEXT}
				onClick={onOpenPopover}
				buttonStyle="without-uppercase header__user-menu position-relative"
			>
				<span className={`header__user-menu-title p-r-half ${hasUserOrCompanyName
					? 'text-pre-wrap'
					: ''
				}`}>
					{hasUserName ? (
						<span className="text-small line-height-0">{userName}</span>
					) : null}
					{hasCompanyName ? (
						<span className="text-small text-semi-bold">
							{hasUserName ? '\n' : ''}
							{getTeamTitle(user.team)}
						</span>
					) : null}
					<span className="text-smaller text-dark-grey">
						{hasUserOrCompanyName ? '\n' : ''}
						{user.role}
					</span>
				</span>
				<span className="header__user-menu__icons">
					<Avatar
						name={user.role === RoleEnumType.COMPANY
							? user.team?.name || 'C'
							: user.name}
						avatar={user.role === RoleEnumType.COMPANY
							? user.team?.logo
							: user.avatar}
						size={36}
						fontSize={21}
					/>
					{user.role === RoleEnumType.COMPANY
					&& !isPopUpOpen
					&& !user.team?.name ? (
						<Error className="company-not-filled "/>
					) : null}
					<ArrowForward
						isActive={isPopUpOpen}
					/>
				</span>
			</Button>
			<Popover
				className="user-menu"
				open={isPopUpOpen}
				onClosePopover={onClosePopover}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				showArrow
			>
				<div className="p-t-half p-b-half p-l-half p-r-half width-full">
					<p className="text-semi-dark text-small m-b-quarter">
						Role
					</p>
					<ToggleButtonGroup
						exclusive
						fullWidth
						orientation="vertical"
						color="primary"
						value={user.role}
						className="menu-account-role"
						onChange={onRoleChange}
					>
						<ToggleButton
							value={RoleEnumType.CONTRACTOR}
							aria-label={RoleEnumType.CONTRACTOR}
							className="align-items-start"
						>
							<CheckOutlined fontSize="small" className={user.role === RoleEnumType.CONTRACTOR ? '' : 'hide'} />
							<span className="pointer-events-none without-uppercase text-semi-bold text-small m-l-quarter">
								Contractor
							</span>
						</ToggleButton>
						<ToggleButton
							value={RoleEnumType.COMPANY}
							aria-label={RoleEnumType.COMPANY}
							className="align-items-start"
						>
							<CheckOutlined fontSize="small" className={user.role === RoleEnumType.COMPANY ? '' : 'hide'} />
							<span className="pointer-events-none without-uppercase text-semi-bold text-small m-l-quarter">
								Company
							</span>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>

				<div className="header-menu-divider width-full" />

				{user.role === RoleEnumType.COMPANY && userTeams.length ? (
					<>
						<div className="p-t-half p-b-half p-l-half p-r-half width-full">
							<p className="text-semi-dark text-small m-b-quarter">
								Company
							</p>
							<ToggleButtonGroup
								exclusive
								fullWidth
								orientation="vertical"
								color="primary"
								value={user.team?.id}
								className="menu-account-role"
								onChange={onTeamChange}
							>
								{userTeams.map((userTeam) => {
									const isCurrentTeam = user.team?.id === userTeam.id
									const currentTeam = isCurrentTeam
										? user.team
										: userTeam

									return (
										<ToggleButton
											key={userTeam.id}
											value={userTeam.id}
											className="align-items-start"
										>
											<CheckOutlined
												fontSize="small"
												className={isCurrentTeam ? '' : 'hide'}
											/>
											<span className={`pointer-events-none without-uppercase text-semi-bold text-small m-l-quarter m-r-quarter ${!currentTeam?.name ? 'text-warning' : ''}`}>
												{getTeamTitle(currentTeam)}
											</span>
										</ToggleButton>
									)
								})}
							</ToggleButtonGroup>

							{userTeams.every((userTeam) => user.team?.id === userTeam.id
								? user.team?.name
								: userTeam.name,
							) ? (
								<div className="m-t-half">
									<Button
										type="button"
										variant={TEXT}
										onClick={onCreateCompany}
										buttonStyle="without-uppercase"
									>
										<AddOutlined className="m-r-half"/>
										Create new
									</Button>
								</div>
							) : null}
						</div>

						<div className="header-menu-divider width-full" />
					</>
				) : null}

				<div className="p-t-half p-b-half p-l-half p-r-half width-full">
					<UserMenuLink
						path={paths.profile}
						currentPath={pathname}
						Icon={Settings}
					>
						Settings
						{user.role === RoleEnumType.COMPANY
						&& isPopUpOpen
						&& !user.team?.name ? (
							<Error className="company-not-filled "/>
						) : null}
					</UserMenuLink>

					<Button
						type="button"
						variant={TEXT}
						onClick={onLogout}
						buttonStyle="without-uppercase margin-top"
					>
						<Logout className="m-r-half" />
						Logout
					</Button>
				</div>
			</Popover>

			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				component={(
					<CompanyProfileFormModal
						onClose={closeModal}
						isCreateMode
					/>
				)}
			/>
		</>
	)
}

export default UserMenu
