import done from 'assets/done-green.svg'

import React from 'react'
import { Button } from 'share'
import buttonStyles from 'constants/buttonSettings'

const { CONTAINED, PRIMARY, SMALL_H } = buttonStyles

interface TodoItemRequired {
	children: React.ReactNode
	isComplete: boolean
	onClick: () => void
	disabled?: boolean
}

const defaultProps = {
	disabled: false
}

const TodoItem: React.FC<TodoItemRequired> = ({
	children,
	isComplete,
	onClick,
	disabled = false
}) =>
	isComplete ? (
		<img src={done} alt="Done" height={28} />
	) : (
		<div className="flex-1">
			<Button
				type="button"
				variant={CONTAINED}
				buttonStyle={`${PRIMARY} ${SMALL_H} ws-nowrap`}
				onClick={onClick}
				disabled={disabled}
			>
				{children}
			</Button>
		</div>
	)

TodoItem.defaultProps = defaultProps

export default TodoItem
