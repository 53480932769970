import React from 'react'
import Navigation from 'navigation'
import Notification from 'components/Notification'

const App = () => (
	<>
		<Navigation />
		<Notification />
		<svg height="0" width="0" display="block">
			<defs>
				<linearGradient id="svgGradient" gradientTransform="rotate(110)">
					<stop offset="0%" stopColor="#d07ce6" />
					<stop offset="100%" stopColor="#564ae4" />
				</linearGradient>
			</defs>
		</svg>
		<svg height="0" width="0" display="block">
			<defs>
				<linearGradient id="svgDangerGradient" gradientTransform="rotate(110)">
					<stop offset="0%" stopColor="#AB69E0" />
					<stop offset="100%" stopColor="#EA2525" />
				</linearGradient>
			</defs>
		</svg>
		<svg height="0" width="0" display="block">
			<defs>
				<linearGradient id="svgSuccessGradient" gradientTransform="rotate(110)">
					<stop offset="0%" stopColor="#12B1BB" />
					<stop offset="100%" stopColor="#A3E90E" />
				</linearGradient>
			</defs>
		</svg>
	</>
)

export default App
