export const CHECKOUT_PAGE_INVOICE_IDS = 'checkoutPageInvoiceIds'

export const WITHDRAW_PROPS = 'withdrawProps'

export const REFUND_PROPS = 'voidProps'

export const PAY_DATA = 'payData'

export const AUTH = 'auth'

export const INVITE_PROPS = 'inviteProps'

export const SIGNUP_ROLE = 'signupRole'

export const PREVENT_UNLOAD = 'preventUnload'

export const USER_PROTOCOL = 'userProtocol'

export const BSC_WALLET = 'bscWallet'

export const ONBOARDING_STATE = 'onboardingState'

export const INVOICE_JOYRIDE_COMPLETE = 'invoiceJoyrideComplete'
