import Balances from 'components/Wallet/components/Balances'

import buttonStyles from 'constants/buttonSettings'

import React, { Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ProtocolEnumType, WalletEnumType } from 'redux/invoice/types'
import { onOpenNotification } from 'redux/notification/reducer'
import { INotificationState } from 'redux/notification/types'
import { setUserAccountRequest } from 'redux/user/reducer'
import { selectUser } from 'redux/user/selectors'
import { IUser, IUserWallet } from 'redux/user/types'
import {
	resetWalletAccount,
	setWalletAccount,
} from 'redux/walletAccount/reducer'

import { selectWalletAccount } from 'redux/walletAccount/selectors'
import { IAccountState } from 'redux/walletAccount/types'

import { Button, TextCopy } from 'share'
import { TRON_REFRESH_TIMEOUT } from 'utils/userUtils'
import {
	getWalletExtensionNotAvailableMessage,
	updateTronTokensBalance,
} from 'utils/wallets'

const { PRIMARY, MEDIUM_H, CONTAINED } = buttonStyles

let currentTronAddress: string | undefined

export const connectTron = async (
	user: IUser,
	dispatch: Dispatch<{
		payload:
			| IUserWallet
			| IAccountState
			| Pick<INotificationState, 'message' | 'notificationType'>
	}>
) => {
	if (!window.tronWeb) {
		dispatch(
			onOpenNotification({
				message: getWalletExtensionNotAvailableMessage(WalletEnumType.TRONLINK_WALLET),
				notificationType: 'info',
			})
		)

		return
	}

	const result = await window.tronWeb.request({
		method: 'tron_requestAccounts',
	})

	if (!result) {
		dispatch(
			onOpenNotification({
				message: `Please unlock ${WalletEnumType.TRONLINK_WALLET} extension and try again`,
				notificationType: 'info',
			})
		)

		return
	}

	if (result.code !== 200) return

	const tronAddress =
		window.tronWeb &&
		window.tronWeb.defaultAddress &&
		window.tronWeb.defaultAddress.base58

	if (!tronAddress) return

	currentTronAddress = tronAddress

	updateTronTokensBalance(dispatch)

	dispatch(
		setWalletAccount({
			walletAccount: tronAddress,
			walletProtocol: ProtocolEnumType.TRON,
		})
	)

	if (tronAddress !== user.account_id) {
		dispatch(
			setUserAccountRequest({
				accountId: tronAddress as string,
				protocol: ProtocolEnumType.TRON,
				wallet: WalletEnumType.TRONLINK_WALLET,
			})
		)
	}
}
const TronProtocolWallet: React.FC<{ children?: React.ReactNode }> = ({
	children,
}) => {
	const dispatch = useDispatch()

	const { walletAccount, walletProtocol, walletBalances } =
		useSelector(selectWalletAccount)
	const { user } = useSelector(selectUser)

	const onConnectClick = async () => {
		await connectTron(user, dispatch)
	}

	const handleDisconnect = () => {
		dispatch(
			resetWalletAccount()
		)
	}

	useEffectOnce(() => {
		if (
			window.tronWeb &&
			window.tronWeb.defaultAddress &&
			window.tronWeb.defaultAddress.base58 &&
			user.account_id === window.tronWeb.defaultAddress.base58
		) {
			connectTron(user, dispatch).catch()
		}

		const interval = setInterval(() => {
			if (
				window.tronWeb &&
				currentTronAddress &&
				window.tronWeb.defaultAddress &&
				window.tronWeb.defaultAddress.base58 &&
				currentTronAddress !== window.tronWeb.defaultAddress.base58
			) {
				connectTron(user, dispatch).catch()
			} else if (window.tronWeb && window.tronWeb.defaultAddress === false) {
				currentTronAddress = undefined

				dispatch(resetWalletAccount())
			}
		}, TRON_REFRESH_TIMEOUT)

		return () => {
			clearInterval(interval)

			dispatch(resetWalletAccount())
		}
	})

	if (!walletAccount) {
		return (
			<>
				{children}
				<div className="connect-wallet__wrapper flex-column m-t width-full">
					<Button
						type="button"
						variant={CONTAINED}
						buttonStyle={`${PRIMARY} ${MEDIUM_H}`}
						onClick={onConnectClick}
					>
						Connect TronLink
					</Button>
					<p className="m-t-half text-pre-wrap text-right">
						Don&apos;t have wallet?&nbsp;Try&nbsp;<a
							href="https://www.tronlink.org/dlDetails/"
							target="_blank"
							rel="noopener noreferrer"
							className="text-underline"
						>
							TronLink
						</a>
					</p>
				</div>
			</>
		)
	}

	return (
		<>
			{children}
			<div className="current-balance__wallet-balance flex-column">
				<TextCopy text={walletAccount} />
				{walletBalances && walletProtocol === ProtocolEnumType.TRON && (
					<Balances
						walletProtocol={walletProtocol}
						walletBalances={walletBalances}
					/>
				)}
			</div>
			<div className="m-t-quarter text-right width-full">
				<Button
					onClick={handleDisconnect}
					type="button"
					variant="text"
					buttonStyle="without-uppercase"
				>
					Disconnect
				</Button>
			</div>
		</>
	)
}

TronProtocolWallet.defaultProps = {
	children: undefined,
}

export default TronProtocolWallet
