// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-information-required .lock-icon{font-size:64px}`, "",{"version":3,"sources":["webpack://./src/components/CompanyInformationRequired/index.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGC,yCACC,cCEc","sourcesContent":["@import 'styles/index.scss';\n\n.company-information-required {\n\t.lock-icon {\n\t\tfont-size: $largeIconSize;\n\t}\n}\n","$blockBorderRadius: 12px;\n\n$general-padding-wrapper: 24px;\n$half-padding-wrapper: 12px;\n$quarter-padding-wrapper: 6px;\n\n$largeIconSize: 64px;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
