import React, { useState } from 'react'

import {
	Avatar as AvatarMUI, CircularProgress,
	Divider, IconButton, Tooltip,
} from '@mui/material'

import AvatarEdit from 'react-avatar-edit'
import { Button, H2, H4, Modal } from 'share'

import { useDispatch, useSelector } from 'react-redux'
import { Clear, Delete, Upload } from '@mui/icons-material'
import { useUpdateEffect } from 'react-use'
import { selectUser } from 'redux/user/selectors'
import {
	deleteUserAvatarRequest,
	uploadUserAvatarRequest,
} from 'redux/user/reducer'
import buttonStyles from 'constants/buttonSettings'

import './index.scss'
import { getFirstChar, hasTeamUserRoles } from 'utils/userUtils'
import { TeamRoleEnumType } from 'redux/user/types'

const { SECONDARY, PRIMARY, FULL_W, MEDIUM_H, CONTAINED, MARGIN_LEFT } =
	buttonStyles

const iconSize = 24

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();

	return new File([blob], fileName, { type: 'image/jpeg' });
}

interface IProfileAvatarProps {
	isTeam?: boolean
}

const defaultProps: IProfileAvatarProps = {
	isTeam: false
}

const ProfileAvatar: React.FC<IProfileAvatarProps> = ({
	isTeam = false
}) => {
	const { user, userTeamRoles, isUploadUserAvatarLoading, isUploadUserAvatarSuccess, isDeleteUserAvatarLoading } = useSelector(selectUser)
	const dispatch = useDispatch()

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [preview, setPreview] = useState<string | undefined>()

	const onReset = () => {
		setPreview(undefined)
	}

	const onCrop = (p: string) => {
		setPreview(p)
	}

	const onModalClose = () => {
		setIsModalOpen(false)

		onReset()
	}

	const onSave = async () => {
		if (preview) {
			const file = await dataUrlToFile(preview, 'avatar.jpg')

			dispatch(
				uploadUserAvatarRequest({ file, isTeam })
			)
		}
	}

	const deleteAvatar = () => {
		dispatch(
			deleteUserAvatarRequest({ isTeam })
		)
	}

	useUpdateEffect(() => {
		if (isUploadUserAvatarSuccess) {
			onModalClose()
		}
	}, [isUploadUserAvatarSuccess])

	return (
		<div className="avatar-wrapper">
			{(isTeam ? user.team?.logo : user.avatar) ? (
				<img
					width={96}
					height={96}
					src={isTeam ? user.team?.logo : user.avatar}
					alt="Avatar"
					className="profile-settings-avatar avatar"
				/>
			) : (
				<AvatarMUI
					className="profile-settings-avatar h1"
					sx={{ width: 96, height: 96, fontSize: 48 }}
				>
					{isTeam ? getFirstChar(user.team?.name, 'C') : getFirstChar(user.name)}
				</AvatarMUI>
			)}
			<span className="avatar-background"/>
			{!isTeam || hasTeamUserRoles(userTeamRoles, [TeamRoleEnumType.TEAM_OWNER, TeamRoleEnumType.TEAM_ADMIN]) ? (
				<div className="modify-avatar">
					<div
						className="flex-column space-between height-full p-t-half p-b-half"
					>
						<Tooltip
							title={`Change ${isTeam ? 'logo' : 'avatar'}`}
							placement="top"
							arrow
						>
							<IconButton
								disableRipple
								onClick={() => setIsModalOpen(true)}
								component="label"
								sx={{ padding: 0 }}
							>
								<Upload
									sx={{
										fontSize: iconSize,
										width: iconSize,
										height: iconSize,
									}}
								/>
							</IconButton>
						</Tooltip>

						<Divider/>

						<Tooltip
							title={`Remove ${isTeam ? 'logo' : 'avatar'}`}
							placement="bottom"
							arrow
						>
							<IconButton
								disableRipple
								sx={{ padding: 0 }}
								onClick={deleteAvatar}
								disabled={isDeleteUserAvatarLoading}
							>
								<Delete
									sx={{
										fontSize: iconSize,
										width: iconSize,
										height: iconSize,
									}}
								/>
							</IconButton>
						</Tooltip>
					</div>
				</div>
			) : null}

			<Modal
				isOpen={isModalOpen}
				onClose={onModalClose}
				component={(
					<>
						<H2 className="flex align-items-center text-semi-bold justify-center">
							Change {isTeam ? 'logo' : 'avatar'}
							<IconButton
								onClick={onModalClose}
								className="close"
							>
								<Clear fontSize="large" />
							</IconButton>
						</H2>
						<div className="flex m-t-half">
							<div className={!preview ? 'flex flex-1 justify-center' : ''}>
								{preview ? (
									<H4 className="m-b-quarter text-center">Scale & crop</H4>
								) : null}
								{/* @ts-ignore */}
								<AvatarEdit
									height={186}
									imageHeight={186}
									exportAsSquare
									exportMimeType="image/jpeg"
									exportSize={186}
									exportQuality={.92}
									onCrop={onCrop}
									onClose={onReset}
									label="Select an image"
								/>
							</div>

							{preview ? (
								<div className="m-l flex-column flex-1 align-items-center">
									<H4 className="m-b-quarter">Check the result</H4>
									<img
										width={96}
										height={96}
										src={preview}
										alt="Avatar preview"
										className="avatar"
									/>
								</div>
							) : null}
						</div>

						<div className="form__buttons m-t">
							<Button
								type="button"
								buttonStyle={`${SECONDARY} ${MEDIUM_H} ${FULL_W}`}
								variant={CONTAINED}
								onClick={onModalClose}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								buttonStyle={`${PRIMARY} ${FULL_W} ${MEDIUM_H} ${MARGIN_LEFT}`}
								variant={CONTAINED}
								disabled={!preview || isUploadUserAvatarLoading}
								onClick={onSave}
							>
								Save
								{isUploadUserAvatarLoading ? (
									<CircularProgress
										className="m-l-half"
										color="secondary"
										size={18}
										thickness={5}
									/>
								) : null}
							</Button>
						</div>
					</>
				)}
			/>
		</div>
	)
}

ProfileAvatar.defaultProps = defaultProps

export default ProfileAvatar
