import paths from 'navigation/paths'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { selectUser } from 'redux/user/selectors'
import { RoleEnumType, SubscriptionEnumType } from 'redux/user/types'
import { Button } from 'share'
import buttonSettings from 'constants/buttonSettings'

import { useLocation, useNavigate } from 'react-router'
import LinkUnderline from '../LinkUnderline'

import './index.scss'

const { CONTAINED, PRIMARY, MEDIUM_H } =
	buttonSettings

const Nav= () => {
	const navigate = useNavigate()

	const { pathname } = useLocation()
	const { user } = useSelector(selectUser)

	const listLinks =
		user.role === RoleEnumType.COMPANY
			? [
					{
						to: paths.invoices,
						title: 'Payment requests',
					},
					{
						to: paths.contractors,
						title: 'Contractors',
					},
					{
						to: paths.invoiceReports,
						title: 'Reports',
					},
			  ]
			: [
					{
						to: paths.invoices,
						title: 'Payment requests',
					},
					{
						to: paths.clients,
						title: 'Clients',
					},
					{
						to: paths.invoiceReports,
						title: 'Reports',
					},
			  ]

	return (
		<nav>
			<ul className="nav__list">
				{listLinks.map(({ to, title }) => (
					<li
						key={title}
						className="m-r-half m-l-half"
					>
						<Link
							className={`nav__link ${
								pathname.includes(to) ? 'active' : ''
							}`}
							to={to}
						>
							<KeyboardArrowRightIcon
								fontSize="small"
								style={{
									stroke: 'none',
									opacity: 0,
								}}
							/>
							{title}
							<KeyboardArrowRightIcon
								fontSize="small"
								style={{
									stroke: 'none',
									opacity: pathname.includes(to) ? 0 : 1,
								}}
							/>
							{pathname.includes(to) && <LinkUnderline/>}
						</Link>
					</li>
				))}
				{pathname !== paths.companySubscription
				&& user.role === RoleEnumType.COMPANY
				&& user.team?.subscription === SubscriptionEnumType.SOLO ? (
					<li className="flex align-items-center m-r-half m-l-half">
						<Button
							type="button"
							buttonStyle={`${PRIMARY} ${MEDIUM_H}`}
							variant={CONTAINED}
							onClick={() => navigate(paths.companySubscription)}
						>
							Try Team plan
						</Button>
					</li>
				) : null}
			</ul>
		</nav>
	)
}

export default Nav
