import { setupNearWallet } from '@near-wallet-selector/near-wallet'
import { setupSender } from '@near-wallet-selector/sender'
import app_config from 'config/app_config'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { map, distinctUntilChanged } from 'rxjs'
import { setupWalletSelector } from '@near-wallet-selector/core'
import type { WalletSelector, AccountState } from '@near-wallet-selector/core'
import { setupModal } from '@near-wallet-selector/modal-ui'
import type { WalletSelectorModal } from '@near-wallet-selector/modal-ui'
import nearWalletIcon from 'assets/near-wallet-icon.svg'
import { useDispatch } from 'react-redux'
import { onOpenNotification } from 'redux/notification/reducer'

declare global {
	interface Window {
		selector: WalletSelector
		modal: WalletSelectorModal
		ethereum: any
	}
}

interface WalletSelectorContextValue {
	selector: WalletSelector
	modal: WalletSelectorModal
	accounts: Array<AccountState>
	accountId: string | null
}

interface IWalletSelectorContextProvider {
	children?: React.ReactNode
}

type Wallets = 'near'
type Network = 'testnet' | 'mainnet'

const WalletSelectorContext =
	React.createContext<WalletSelectorContextValue | null>(null)

const walletSettings = {
	near: {
		setupWallet: setupNearWallet,
		iconUrl: nearWalletIcon,
		network: {
			testnet: 'https://testnet.mynearwallet.com',
			mainnet: 'https://app.mynearwallet.com',
		},
	},
}

const wallets: Wallets[] = ['near']

const defaultProps = {
	children: undefined,
}

export const WalletSelectorContextProvider: React.FC<IWalletSelectorContextProvider> = ({ children }) => {
	const [selector, setSelector] = useState<WalletSelector | null>(null)
	const [modal, setModal] = useState<WalletSelectorModal | null>(null)
	const [accounts, setAccounts] = useState<Array<AccountState>>([])

	const dispatch = useDispatch()
	const init = useCallback(async () => {
		const network = app_config.env.REACT_APP_NEAR_NETWORK as Network

		const selectorModules = wallets.map((walletName) => {
			const wallet = walletSettings[walletName]

			return wallet.setupWallet({
				walletUrl: wallet.network[network],
				iconUrl: wallet.iconUrl,
			})
		})

		const _selector = await setupWalletSelector({
			network,
			modules: [...selectorModules, setupSender()],
		})

		const _modal = setupModal(_selector, {
			contractId: app_config.env.REACT_APP_NEAR_CONTRACT as string, // TODO: Add to default CONTRACT_ID
		})
		const state = _selector.store.getState()

		setAccounts(state.accounts)

		window.selector = _selector
		window.modal = _modal

		setSelector(_selector)
		setModal(_modal)
	}, [])

	useEffect(() => {
		init().catch((err) => {
			dispatch(onOpenNotification({ message: err, notificationType: 'error' }))
		})
	}, [init])

	useEffect(() => {
		if (!selector) {
			return
		}

		const subscription = selector.store.observable
			.pipe(
				map((state) => state.accounts),
				distinctUntilChanged()
			)
			.subscribe((nextAccounts) => {
				setAccounts(nextAccounts)
			})

		// eslint-disable-next-line consistent-return
		return () => subscription.unsubscribe()
	}, [selector])

	if (!selector || !modal) {
		return null
	}

	const accountId =
		accounts.find((account) => account.active)?.accountId || null

	return (
		<WalletSelectorContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				selector,
				modal,
				accounts,
				accountId,
			}}
		>
			{children}
		</WalletSelectorContext.Provider>
	)
}

WalletSelectorContextProvider.defaultProps = defaultProps

export function useWalletSelector() {
	const context = useContext(WalletSelectorContext)

	if (!context) {
		throw new Error(
			'useWalletSelector must be used within a WalletSelectorContextProvider'
		)
	}

	return context
}
