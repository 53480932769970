import app_config from 'config/app_config'
import React from 'react'
import { ProtocolEnumType } from 'redux/invoice/types'
import {
	WalletAccountBalances,
	WalletAccountBalanceToken,
} from 'redux/walletAccount/types'
import { ExtraSmallParagraph } from 'share'

import { useSelector } from 'react-redux'
import { selectCoinPrices } from 'redux/coinPrices/selectors'
import { CoinPriceSymbols } from 'redux/coinPrices/types'
import UserGeekPayBalance from '../UserGeekPayBalance'

interface IBalances {
	walletProtocol: ProtocolEnumType
	walletBalances: WalletAccountBalances
}

const Balances: React.FC<IBalances> = ({ walletProtocol, walletBalances }) => {
	const { coinPrices } = useSelector(selectCoinPrices)

	return (
		<div className="flex-column m-t-half width-full">
			<div className="flex-column m-t-half width-full">
				{walletProtocol === ProtocolEnumType.NEAR ? (
					<>
						{walletBalances[
							app_config.env.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_NEAR_USDTT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.NEAR_USDTT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_NEAR_USDT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_NEAR_USDT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.NEAR_USDT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_NEAR_USDC_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_NEAR_USDC_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.NEAR_USDC}
								coinPrice={coinPrices[CoinPriceSymbols.USDC].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_NEAR_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env.REACT_APP_NEAR_TOKEN as WalletAccountBalanceToken
										]
								}
								token={
									app_config.env.REACT_APP_NEAR_TOKEN as WalletAccountBalanceToken
								}
								coinPrice={coinPrices[CoinPriceSymbols.NEAR].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_NEAR_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={walletBalances[WalletAccountBalanceToken.NEAR]}
								token={WalletAccountBalanceToken.NEAR}
								coinPrice={coinPrices[CoinPriceSymbols.NEAR].usd}
							/>
						) : null}
					</>
				) : null}
				{walletProtocol === ProtocolEnumType.ETH ? (
					<>
						{walletBalances[
							app_config.env.REACT_APP_ETH_USDT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_ETH_USDT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.ETH_USDT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_ETH_USDC_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_ETH_USDC_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.ETH_USDC}
								// @ts-ignore
								coinPrice={coinPrices[CoinPriceSymbols.USDC].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_ETH_BUSD_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_ETH_BUSD_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.ETH_BUSD}
								coinPrice={coinPrices[CoinPriceSymbols.BUSD].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_ETH_GUSD_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_ETH_GUSD_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.ETH_GUSD}
								coinPrice={coinPrices[CoinPriceSymbols.GUSD].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_ETH_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env.REACT_APP_ETH_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.WRAP_ETH}
								coinPrice={coinPrices[CoinPriceSymbols.ETH].usd}
							/>
						) : null}
						{walletBalances[WalletAccountBalanceToken.ETH] ? (
							<UserGeekPayBalance
								balance={walletBalances[WalletAccountBalanceToken.ETH]}
								token={WalletAccountBalanceToken.ETH}
								coinPrice={coinPrices[CoinPriceSymbols.ETH].usd}
							/>
						) : null}
					</>
				) : null}
				{walletProtocol === ProtocolEnumType.BSC ? (
					<>
						{walletBalances[
							app_config.env.REACT_APP_BSC_USDT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_BSC_USDT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.BSC_USDT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_BSC_USDC_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_BSC_USDC_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.BSC_USDC}
								coinPrice={coinPrices[CoinPriceSymbols.USDC].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_BSC_BUSD_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_BSC_BUSD_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.BSC_BUSD}
								coinPrice={coinPrices[CoinPriceSymbols.BUSD].usd}
							/>
						) : null}
						{walletBalances[WalletAccountBalanceToken.BSC_BNB] ? (
							<UserGeekPayBalance
								balance={walletBalances[WalletAccountBalanceToken.BSC_BNB]}
								token={WalletAccountBalanceToken.BSC_BNB}
								coinPrice={coinPrices[CoinPriceSymbols.BSC].usd}
							/>
						) : null}
					</>
				) : null}
				{walletProtocol === ProtocolEnumType.POLYGON ? (
					<>
						{walletBalances[
							app_config.env.REACT_APP_POLYGON_USDT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_POLYGON_USDT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.POLYGON_USDT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_POLYGON_USDC_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_POLYGON_USDC_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.POLYGON_USDC}
								coinPrice={coinPrices[CoinPriceSymbols.USDC].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_POLYGON_BUSD_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_POLYGON_BUSD_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.POLYGON_BUSD}
								coinPrice={coinPrices[CoinPriceSymbols.BUSD].usd}
							/>
						) : null}
						{walletBalances[WalletAccountBalanceToken.POLYGON_MATIC] ? (
							<UserGeekPayBalance
								balance={walletBalances[WalletAccountBalanceToken.POLYGON_MATIC]}
								token={WalletAccountBalanceToken.POLYGON_MATIC}
								coinPrice={coinPrices[CoinPriceSymbols.POLYGON].usd}
							/>
						) : null}
					</>
				) : null}
				{walletProtocol === ProtocolEnumType.TRON ? (
					<>
						{walletBalances[
							app_config.env.REACT_APP_TRON_USDT_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env
											.REACT_APP_TRON_USDT_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.TRON_USDT}
								coinPrice={coinPrices[CoinPriceSymbols.USDT].usd}
							/>
						) : null}
						{walletBalances[
							app_config.env.REACT_APP_TRON_TOKEN as WalletAccountBalanceToken
							] ? (
							<UserGeekPayBalance
								balance={
									walletBalances[
										app_config.env.REACT_APP_TRON_TOKEN as WalletAccountBalanceToken
										]
								}
								token={WalletAccountBalanceToken.WRAP_TRON}
								coinPrice={coinPrices[CoinPriceSymbols.TRON].usd}
							/>
						) : null}
						{walletBalances[WalletAccountBalanceToken.TRON] ? (
							<UserGeekPayBalance
								balance={walletBalances[WalletAccountBalanceToken.TRON]}
								token={WalletAccountBalanceToken.TRON}
								coinPrice={coinPrices[CoinPriceSymbols.TRON].usd}
							/>
						) : null}
					</>
				) : null}

				<ExtraSmallParagraph className="p-t-quarter text-right text-smaller">
					Prices powered by{' '}
					<a
						href="https://www.coingecko.com/en/api"
						target="_blank"
						rel="noreferrer"
						className="text-smaller text-semi-bold"
					>
						CoinGecko
					</a>
				</ExtraSmallParagraph>
			</div>
		</div>
	)
}

export default Balances
