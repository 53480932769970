import { AddComment, Support } from '@mui/icons-material'
import buttonStyles from 'constants/buttonSettings'
import React from 'react'
import helpIcon from 'assets/help.svg'

import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/selectors'
import { Button, Popover } from 'share'
import { getFullUserTitle } from 'utils/userUtils'

import './index.scss'

const { TEXT, MARGIN_TOP } = buttonStyles

const Help = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLImageElement>(null)

	const open = Boolean(anchorEl)

	const { user } = useSelector(selectUser)

	const mailtoLink = `mailto:support@geekpay.co?subject=Support request by ${getFullUserTitle(
		user
	)}&body=Hey Geekpay support!%0D%0A%0D%0AI’m having a problem with:%0D%0A%0D%0ADescribe your problem:%0D%0A%0D%0AThanks,%0D%0A${getFullUserTitle(
		user
	)}`

	const onContactSupport = () => {
		window.open(mailtoLink)

		setAnchorEl(null)
	}
	const onFeatureRequest = () => {
		window.open('https://feedback.geekpay.co/')

		setAnchorEl(null)
	}

	const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<div className="toggle-help-icon-absolute">
				<div
					className="toggle-help-icon p-t-half"
					aria-hidden
					onClick={handleClick}
					aria-controls={open ? 'help-menu' : undefined}
				>
					<img src={helpIcon} className="cursor-pointer" alt="Get help" />
				</div>
			</div>
			<Popover
				open={!!anchorEl}
				onClosePopover={handleClose}
				anchorEl={anchorEl as unknown as HTMLButtonElement}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				showArrow={false}
			>
				<div className="padding flex-column align-items-start">
					<Button
						type="button"
						variant={TEXT}
						onClick={onFeatureRequest}
						buttonStyle="without-uppercase"
					>
						<AddComment className="m-r-half" />
						&nbsp;Leave suggestion or feedback
					</Button>
					<Button
						type="button"
						variant={TEXT}
						onClick={onContactSupport}
						buttonStyle={`without-uppercase ${MARGIN_TOP}`}
					>
						<Support className="m-r-half" />
						&nbsp;Contact support via email
					</Button>
				</div>
			</Popover>
		</>
	)
}

export default Help
