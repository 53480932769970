interface ButtonStyles {
	PRIMARY: 'primary'
	SECONDARY: 'secondary'
	SUCCESS: 'success'
	DANGER: 'danger'
	OUTLINE: 'outline'
	SMALL_W: 'width-small'
	MEDIUM_W: 'width-medium'
	BIG_W: 'width-big'
	FULL_W: 'width-full'
	SMALL_H: 'height-small'
	MEDIUM_H: 'height-medium'
	BIG_H: 'height-big'
	TEXT: 'text'
	CONTAINED: 'contained'
	OUTLINED: 'outlined'
	ROUNDED: 'rounded'
	MARGIN_LEFT: 'margin-left'
	MARGIN_RIGHT: 'margin-right'
	MARGIN_TOP: 'margin-top'
}

const buttonStyles: ButtonStyles = {
	// color
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	OUTLINE: 'outline',
	SUCCESS: 'success',
	DANGER: 'danger',
	// size width
	SMALL_W: 'width-small',
	MEDIUM_W: 'width-medium',
	BIG_W: 'width-big',
	FULL_W: 'width-full',
	// size height
	SMALL_H: 'height-small',
	MEDIUM_H: 'height-medium',
	BIG_H: 'height-big',
	// variants
	TEXT: 'text',
	CONTAINED: 'contained',
	OUTLINED: 'outlined',
	ROUNDED: 'rounded',
	// other settings
	MARGIN_LEFT: 'margin-left',
	MARGIN_RIGHT: 'margin-right',
	MARGIN_TOP: 'margin-top',
}

export default buttonStyles
