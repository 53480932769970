import React from 'react'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'
import { ExtraSmallParagraph, SemiBoldParagraph } from 'share'
import { tokenSymbols } from 'utils/invoiceUtils'
import { getIconByToken } from 'utils/wallets'

interface IUserGeekPayBalanceOptional {
	coinPrice?: number
}

interface IUserGeekPayBalanceRequired {
	balance: string | null | undefined
	token: string
}

interface IUserGeekPayBalance
	extends IUserGeekPayBalanceRequired,
		IUserGeekPayBalanceOptional {}

const UserGeekPayBalance: React.FC<IUserGeekPayBalance> = ({
	balance,
	token,
	coinPrice = 0,
}) => {
	const currentBalance = balance ? parseFloat(balance) : 0

	return (
		<div className="text-right m-b-quarter ws-nowrap">
			<div className="flex align-items-center justify-end">
				<img
					className="aspect-ratio-1 gray-filter m-r-quarter"
					src={getIconByToken(token as WalletAccountBalanceToken)}
					alt=""
					height={20}
				/>

				<SemiBoldParagraph>
					{currentBalance.toFixed(2)} {tokenSymbols[token as WalletAccountBalanceToken]}
				</SemiBoldParagraph>
			</div>

			{coinPrice ? (
				<ExtraSmallParagraph>
					{(coinPrice * currentBalance).toFixed(2)} $
				</ExtraSmallParagraph>
			) : null}
		</div>
	)
}

UserGeekPayBalance.defaultProps = {
	coinPrice: 0,
}

export default React.memo(UserGeekPayBalance)
