import { Clear } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import paths from 'navigation/paths'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { selectAuthForm } from 'redux/authForm/selectors'

import { Button, H2, HelperText, Input, Logo } from 'share/'

import buttonStyles from 'constants/buttonSettings'

import { useNavigate, useParams } from 'react-router'
import { changePasswordRequest } from 'redux/authForm/reducer'

const {
	TEXT,
	PRIMARY,
	SECONDARY,
	MEDIUM_H,
	FULL_W,
	BIG_H,
	CONTAINED,
	MARGIN_LEFT,
} = buttonStyles

type ITypeOfForm = 'profile' | 'forgotPassword'

interface FormMapper {
	name: 'currentPassword' | 'password'
	label: string

	formType: ITypeOfForm[]
	type: string
}

const FORM_MAPPER: FormMapper[] = [
	{
		name: 'currentPassword',
		label: 'Current Password',
		type: 'password',
		formType: ['profile'],
	},
	{
		name: 'password',
		label: 'Password',
		type: 'password',
		formType: ['forgotPassword', 'profile'],
	},
]

interface IFormInputs {
	currentPassword: string
	password: string
}

const regularFormSchemaFields = {
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password length should be at least 8 characters')
		.max(32, 'Password length must not exceed 32 characters')
}

const profileFormSchemaFields = {
	currentPassword: Yup.string()
		.required('Current password is required')
		.min(8, 'Current password length should be at least 8 characters')
		.max(32, 'Current password length must not exceed 32 characters'),
}

const getFormSchema = (type: ITypeOfForm = 'forgotPassword') =>
	type === 'forgotPassword'
		? { ...regularFormSchemaFields }
		: {
				...profileFormSchemaFields,
				...regularFormSchemaFields,
		  }

type IChangePasswordType = 'forgotPassword' | 'profile'

interface IChangePasswordProps {
	type?: IChangePasswordType
	onClose?: () => void
}

const ChangePassword: React.FC<IChangePasswordProps> = ({
	type = 'forgotPassword',
	onClose,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { verificationCode } = useParams()

	const { isLoading, requestChangePasswordCompletedSuccess } =
		useSelector(selectAuthForm)

	const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
		if (type === 'forgotPassword' && verificationCode) {
			dispatch(changePasswordRequest({ ...formData, verificationCode }))
		} else if (type === 'profile') {
			dispatch(changePasswordRequest({ ...formData }))
		}
	}

	const formSchema = Yup.object().shape(getFormSchema(type))

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm<IFormInputs>({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
		defaultValues: {
			password: '',
			currentPassword: '',
		},
	})

	useEffect(() => {
		if (requestChangePasswordCompletedSuccess) {
			navigate(paths.signIn)
		}
	}, [requestChangePasswordCompletedSuccess])

	return (
		<>
			{type === 'profile' ? (
				<H2 className="flex align-items-center text-semi-bold justify-center">
					Change password
					<IconButton onClick={onClose} className="close">
						<Clear fontSize="large" />
					</IconButton>
				</H2>
			) : null}

			<form className={`form__${type}`} onSubmit={handleSubmit(onSubmit)}>
				{type === 'forgotPassword' ? (
					<Logo logoStyle="logo-height-big logo-in-sign" />
				) : (
					<div className="grey-box flex-1 padding m-t-half m-b-half">
						<p>
							After a successful password update you will be redirected to the
							login page, where you can log in with your new password.
						</p>
					</div>
				)}

				{FORM_MAPPER.map((input) => {
					const isShow = input.formType.includes(type)

					return (
						isShow && (
							<Fragment key={input.name}>
								<Controller
									name={input.name}
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											label={
												<>
													{input.label}
													<span className="form__input-required-mark">*</span>
												</>
											}
											type={input.type}
											value={value as string}
											onChange={onChange}
										/>
									)}
								/>

								<HelperText
									className={errors[input.name] && !isValid ? '' : 'invisible'}
								>
									{errors[input.name]?.message || 'invisible'}
								</HelperText>
							</Fragment>
						)
					)
				})}

				<div className="form__buttons m-t">
					{type === 'profile' ? (
						<Button
							type="button"
							buttonStyle={`${SECONDARY} ${MEDIUM_H} ${FULL_W}`}
							variant={CONTAINED}
							onClick={onClose}
						>
							Cancel
						</Button>
					) : null}
					<Button
						type="submit"
						buttonStyle={
							type === 'forgotPassword'
								? `${SECONDARY} ${FULL_W} ${BIG_H}`
								: `${PRIMARY} ${FULL_W} ${MEDIUM_H} ${MARGIN_LEFT}`
						}
						variant={CONTAINED}
					>
						Change password
					</Button>
				</div>

				{type === 'forgotPassword' && (
					<p className="form__footer">
						Do you remember your password?&nbsp;
						<Button
							type="button"
							variant={TEXT}
							buttonStyle="without-uppercase"
							onClick={() => {
								navigate(paths.auth, { state: 'signIn' })
							}}
						>
							<span className="text-semi-bold">Sign in</span>
						</Button>
					</p>
				)}
				{isLoading ? <span className="loader" /> : null}
			</form>
		</>
	)
}

ChangePassword.defaultProps = {
	type: 'forgotPassword',
	onClose: () => {},
}

export default ChangePassword
