import { SvgIconComponent } from '@mui/icons-material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LinkUnderline from 'components/Header/LinkUnderline'
import React from 'react'

import './index.scss'
import { Link } from 'react-router-dom'

interface IUserMenuLink {
	children: React.ReactNode
	Icon: SvgIconComponent
	path: string
	currentPath: string
}

const UserMenuLink: React.FC<IUserMenuLink> = ({
	children,
	Icon,
	path,
	currentPath,
}) => (
	<Link
		to={path}
		className={`user-menu__link ${currentPath === path ? 'active' : ''}`}
	>
		<Icon className="m-r-half" />
		<span
			className={`user-menu__link-text ws-nowrap text-small text-semi-bold position-relative m-r ${
				currentPath === path ? '' : 'flex-1'
			}`}
		>
			{children}
			{currentPath === path && <LinkUnderline />}
		</span>
		<KeyboardArrowRightIcon
			fontSize="small"
			style={{
				stroke: 'none',
				opacity: currentPath === path ? 0 : 1,
			}}
		/>
	</Link>
)

export default UserMenuLink
