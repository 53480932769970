import buttonStyles from 'constants/buttonSettings'
import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'redux/user/selectors'
import { Button, H2, Modal } from 'share'
import done from 'assets/done-green.svg'

import { Popper } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'

import { selectUserList } from 'redux/userList/selectors'
import { useLocation, useNavigate } from 'react-router'
import paths from 'navigation/paths'
import {
	getUsersRequest,
	setInviteUserModalOpened,
} from 'redux/userList/reducer'
import { selectInvoice } from 'redux/invoice/selectors'
import { setCreateInvoiceModalOpened } from 'redux/invoice/reducer'
import {
	discardUserWelcomeSteps,
	discardWelcomeStepsRequest,
	setCompanyProfileModalOpened, setWalletModalOpened,
} from 'redux/user/reducer'
import ProfileAvatar from 'components/ProfileAvatar'
import { sendGtagEvent, truncateString } from 'utils/userUtils'
import { RoleEnumType } from 'redux/user/types'
import {
	ONBOARDING_STATE,
} from 'constants/localStorageKeys'
import { selectInvoices } from 'redux/invoices/selectors'
import { getProtocolByToken } from 'utils/invoiceUtils'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

import TodoItem from './TodoItem'

import './index.scss'

const { PRIMARY, CONTAINED, MEDIUM_H, BIG_W } = buttonStyles

const ONBOARDING_OPEN_STATE = 'open'
const ONBOARDING_CLOSED_STATE = 'closed'

const WALLET_READY_STATUS = ['pending', 'inReview']

const Onboarding = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { pathname } = useLocation()

	const ref = useRef<HTMLButtonElement>(null)

	const { user } = useSelector(selectUser)
	const { userList } = useSelector(selectUserList)
	const { currentInvoice } = useSelector(selectInvoice)
	const { invoices, invoicesCount } = useSelector(selectInvoices)

	const [open, setOpen] = React.useState(
		localStorage.getItem(ONBOARDING_STATE) === null
			? false
			: localStorage.getItem(ONBOARDING_STATE) !== ONBOARDING_CLOSED_STATE
	);
	const [isModalOpened, setIsModalOpened] = React.useState(true)
	const [tasksCompleted, setTasksCompleted] = React.useState(0)
	const [token, setToken] = React.useState<WalletAccountBalanceToken | null>(null)

	const handleClick = () => {
		setOpen((previousOpen) => !previousOpen);
	};

	const onAddCompanyInfo = () => {
		dispatch(setCompanyProfileModalOpened({ isOpened: true }))

		navigate(paths.companyProfile)
	}

	const onInviteUser = () => {
		dispatch(setInviteUserModalOpened({ isOpened: true }))

		navigate(paths.contractors)
	}

	const onCreateInvoice = () => {
		dispatch(setCreateInvoiceModalOpened({ isOpened: true }))
	}

	const getCompletePercentage = () => {
		const completed = tasksCompleted + 2

		return (completed / 5) * 100
	}

	const closeModal = () => {
		setIsModalOpened(false)

		dispatch(
			discardUserWelcomeSteps()
		)
	}

	const isCompanyInfoComplete = !!user.team?.name
	const isUserInvited = !!userList.length
	const isWalletConnected = currentInvoice.status === 'readyToPay'
		|| !!invoicesCount?.readyToPay
		|| !!(user.account_id && token && getProtocolByToken(token) === user.protocol)
	const isInvoiceCreated = isWalletConnected
		|| ['pending', 'readyToPay', 'inReview'].includes(currentInvoice.status)
		|| !!token

	const isWelcomeStepsCompleted = user.role === RoleEnumType.COMPANY
		? isCompanyInfoComplete && isUserInvited && isInvoiceCreated
		: isUserInvited && isInvoiceCreated && isWalletConnected

	const hideOnboarding = () => {
		setOpen(false)
	}

	const onConnectWallet = async () => {
		dispatch(
			setWalletModalOpened({ isOpened: true })
		)
	}

	useEffect(() => {
		dispatch(getUsersRequest())
	}, [user.role, user.team?.id])

	useEffect(() => {
		let tasksDone = +isUserInvited + +isInvoiceCreated

		if (user.role === RoleEnumType.COMPANY) {
			tasksDone += +isCompanyInfoComplete
		} else {
			tasksDone += +isWalletConnected
		}

		setTasksCompleted(tasksDone)
	}, [
		isCompanyInfoComplete,
		isUserInvited,
		isInvoiceCreated,
		isWalletConnected,
	])

	useEffect(() => {
		if (isWelcomeStepsCompleted) {
			dispatch(
				discardWelcomeStepsRequest()
			)

			sendGtagEvent('onboarding_complete', {
				role: user.role,
			})
		}
	}, [isWelcomeStepsCompleted])

	useEffect(() => {
		if (currentInvoice.id
			&& pathname === paths.invoice.replace(':invoiceId', currentInvoice.id)
			&& currentInvoice.status === 'draft'
		) {
			hideOnboarding()
		}
	}, [pathname, currentInvoice.id, currentInvoice.status])

	useEffect(() => {
		localStorage.setItem(
			ONBOARDING_STATE,
			open
				? ONBOARDING_OPEN_STATE
				: ONBOARDING_CLOSED_STATE
		)
	}, [open])

	useEffect(() => {
		if (WALLET_READY_STATUS.includes(currentInvoice.status)) {
			setToken(currentInvoice.token)
		} else if (invoices.length && invoices.some((invoice) => WALLET_READY_STATUS.includes(invoice.status))) {
			const walletReadyInvoice = invoices.find((invoice) => WALLET_READY_STATUS.includes(invoice.status))

			if (walletReadyInvoice) {
				setToken(walletReadyInvoice.token)
			}
		} else {
			setToken(null)
		}
	}, [invoices, currentInvoice.status])

	useEffect(() => {
		if (token && !open) {
			setOpen(true)
		}
	}, [token])

	return isWelcomeStepsCompleted ? (
		<Modal
			isOpen={isModalOpened}
			onClose={closeModal}
			className="onboarding-steps__congrats-modal flex-column flex justify-center align-items-center"
			component={(
				<>
					<H2>Congratulations! <span className="congrats-emoji">🎉</span></H2>

					{user.role === RoleEnumType.COMPANY ? (
						<p className="text-center text-medium all-set-message">
							You&apos;re all set to go.
							<br/>
							{currentInvoice.status === 'pending'
								? 'We’ll notify you once the contractor connects their wallet to start accepting payments'
								: 'Now you are able to pay the invoice'}.
						</p>
					) : (
						<p className="text-center text-medium all-set-message">
							You&apos;re all set to go.
							<br/>
							We’ll notify you once the client pays the invoice.
						</p>
					)}

					<Button
						type="button"
						buttonStyle={`${PRIMARY} ${MEDIUM_H} ${BIG_W}`}
						variant={CONTAINED}
						onClick={closeModal}
					>
						Got it!
					</Button>
				</>
			)}
		/>
	) : (
		<>
			<div className="toggle-onboarding-absolute">
				<Button
					ref={ref}
					type="button"
					buttonStyle={`${PRIMARY} ${open ? 'active' : ''} gradient-text toggle-onboarding`}
					variant={CONTAINED}
					onClick={handleClick}
				>
					<span className="gradient-text onboarding-text text-semi-bold">
						Onboarding &#124; {getCompletePercentage()}% Complete
					</span>
				</Button>
			</div>

			{ref.current ? (
				<Popper
					open={open}
					anchorEl={ref.current}
					sx={{ zIndex: 10 }}
					popperOptions={{
						placement: 'top-end',
						strategy: 'fixed'
					}}
				>
					<div className="onboarding-steps m-b">
						<div className="onboarding-steps__header flex padding align-items-center">
							<div className="flex-1">
								<H2 className="m-b-quarter text-white">
									Hello{user.name ? ' ' : ''}{truncateString(user.name)}!
								</H2>
								<p className="p-b-quarter text-white text-small">
									We&apos;re excited to have you on board <span className="onboarding-steps__emoji">🎊</span>
								</p>
								{user.role === RoleEnumType.COMPANY ? (
									<p className="text-white text-small">
										This section is your go-to for
										everything you need to know about
										preparing your account for the first
										payment.
									</p>
								) : (
									<p className="text-white text-small">
										This section is your go-to for
										everything you need to know about
										preparing your account for getting
										paid.
									</p>
								)}
							</div>
							<ProfileAvatar />
							<ArrowForwardIos
								className="onboarding-steps__hide text-white"
								onClick={hideOnboarding}
							/>
						</div>

						<div className="onboarding-steps__content padding-half">
							<ol className="onboarding-steps__todos">
								<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
									<p className="width-full text-small">
										Account created
									</p>

									<img src={done} alt="Done" height={28} />
								</li>
								<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
									<p className="width-full  text-small">
										Email verified
									</p>

									<img src={done} alt="Done" height={28} />
								</li>
								{user.role === RoleEnumType.CONTRACTOR ? (
									<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
										<p className="width-full text-small">
											Connect wallet to receive payments
										</p>

										<TodoItem
											isComplete={isWalletConnected}
											onClick={onConnectWallet}
										>
											Add wallet
										</TodoItem>
									</li>
								) : null}
								{user.role === RoleEnumType.COMPANY ? (
									<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
										<p className="width-full text-small">
											Company information added
										</p>

										<TodoItem
											isComplete={isCompanyInfoComplete}
											onClick={onAddCompanyInfo}
										>
											Add now
										</TodoItem>
									</li>
								) : null}
								<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
									<p className="width-full text-small">
										{user.role === RoleEnumType.COMPANY ? 'Contractor' : 'Client'} invited
									</p>

									<TodoItem isComplete={isUserInvited} onClick={onInviteUser}>
										Invite now
									</TodoItem>
								</li>
								<li className="flex grey-box padding-10 width-full align-items-center onboarding-list-btn">
									<p className="width-full text-small">
										Payment request created
									</p>

									<TodoItem isComplete={isInvoiceCreated} onClick={onCreateInvoice}>
										Create now
									</TodoItem>
								</li>
							</ol>
						</div>
					</div>
				</Popper>
			) : null}
		</>
	)
}

export default Onboarding
