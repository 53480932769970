import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'

import { selectNotificationData } from 'redux/notification/selectors'
import { onCloseNotification } from 'redux/notification/reducer'

import {
	useSnackbar,
	closeSnackbar,
	SnackbarAction,
} from 'notistack'
import { useUpdateEffect } from 'react-use'

import './index.scss'

const Notification = () => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const { message, notificationType, preventDuplicate } = useSelector(
		selectNotificationData
	)

	const action: SnackbarAction = snackbarId => (
		<IconButton onClick={() => closeSnackbar(snackbarId)}
								className="clear-icon"
								disableRipple
		>
			<Clear/>
		</IconButton>
	)

	useUpdateEffect(() => {
		if (!message) {
			return
		}

		enqueueSnackbar(message, {
			variant: notificationType ?? 'default',
			action,
			preventDuplicate
		})

		dispatch(
			onCloseNotification()
		)
	}, [message, notificationType])

	return null
}

export default Notification
