import { Clear } from '@mui/icons-material'
import { Autocomplete, Chip, TextField } from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import CompanyInformationRequired from 'components/CompanyInformationRequired'

import buttonSettings from 'constants/buttonSettings'
import paths from 'navigation/paths'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useUpdateEffect } from 'react-use'
import {
	createInvoiceRequest,
	setCreateInvoiceModalOpened,
} from 'redux/invoice/reducer'
import { selectInvoice } from 'redux/invoice/selectors'
import { ProtocolEnumType } from 'redux/invoice/types'
import { onOpenNotification } from 'redux/notification/reducer'
import { selectUser } from 'redux/user/selectors'
import { ITeamRoleUser, RoleEnumType } from 'redux/user/types'
import { getUsersRequest } from 'redux/userList/reducer'
import { selectUserList } from 'redux/userList/selectors'
import { WalletAccountBalanceToken } from 'redux/walletAccount/types'

import { ToggleGroupButton, Button, H2, HelperText, Modal } from 'share'
import { getCustomerRecipientTitle, getDraftInvoice } from 'utils/invoiceUtils'

import {
	blockchainListShort,
	getCurrenciesByProtocol,
	getDefaultTokenByProtocol,
} from 'utils/wallets'
import { USER_PROTOCOL } from 'constants/localStorageKeys'

import './index.scss'

const { SECONDARY, PRIMARY, MEDIUM_H, MARGIN_LEFT, MARGIN_RIGHT, CONTAINED } =
	buttonSettings

const CreateInvoice = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { pathname } = useLocation()

	const [error, setError] = useState<null | string>(null)
	const [users, setUsers] = useState<ITeamRoleUser[]>([])

	const { user } = useSelector(selectUser)
	const { currentInvoice, isInvoiceLoading, isCreateInvoiceModalOpened } = useSelector(selectInvoice)
	const { isLoading, userList } = useSelector(selectUserList)

	const [protocol, setProtocol] = useState<string>(
		localStorage.getItem(USER_PROTOCOL) ?? user.protocol
	)
	const [token, setToken] = useState<string>(
		getDefaultTokenByProtocol(protocol as ProtocolEnumType)
	)

	const onChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
		if (value) {
			setProtocol(value)
		}
	}

	const onChangeCurrency = (
		event: React.MouseEvent<HTMLElement>,
		value: string
	) => {
		if (value) {
			setToken(value)
		}
	}

	const onSubmit = () => {
		if (!users.length) {
			setError(
				`Please select one or more ${
					user.role === RoleEnumType.COMPANY ? 'contractors' : 'clients'
				}`
			)

			return
		}

		const invoices = users.map((myUser) =>
			getDraftInvoice(
				token as WalletAccountBalanceToken,
				user.role === RoleEnumType.COMPANY ? user.id : myUser.id,
				user.role === RoleEnumType.COMPANY ? myUser.id : user.id,
				user.role === RoleEnumType.COMPANY ? user.team : myUser.team
			)
		)

		dispatch(
			createInvoiceRequest({
				invoices,
			})
		)
	}

	const setIsModalOpened = (isOpened: boolean) => {
		dispatch(
			setCreateInvoiceModalOpened({
				isOpened
			})
		)
	}

	const closeModal = () => setIsModalOpened(false)

	useUpdateEffect(() => {
		if (isCreateInvoiceModalOpened) {
			dispatch(getUsersRequest())
		}
	}, [isCreateInvoiceModalOpened])

	useUpdateEffect(() => {
		setToken(getDefaultTokenByProtocol(protocol as ProtocolEnumType))
	}, [protocol])

	useEffect(() => {
		if (currentInvoice?.id && users.length) {
			closeModal()
			setUsers([])

			if (users.length === 1) {
				navigate(paths.invoice.replace(':invoiceId', currentInvoice.id))
			} else {
				navigate(paths.invoices)
			}
		}
	}, [currentInvoice])

	useEffect(() => {
		if (user.role === RoleEnumType.COMPANY && !user.team?.name) {
			return
		}

		if (isCreateInvoiceModalOpened
			&& !isLoading
			&& !userList.length
		) {
			dispatch(
				onOpenNotification({
					message: `You have no ${
						user.role === RoleEnumType.COMPANY ? 'contractors' : 'clients'
					}`,
					notificationType: 'info',
				})
			)
		}
	}, [isLoading])

	return (
		<>
			<Button
				type="button"
				buttonStyle={`${PRIMARY} ${MEDIUM_H} ${MARGIN_RIGHT}`}
				variant={CONTAINED}
				onClick={() => setIsModalOpened(true)}
				disabled={
					isLoading && ![paths.contractors, paths.clients].includes(pathname)
				}
			>
				Create payment request
			</Button>
			<Modal
				isOpen={isCreateInvoiceModalOpened}
				onClose={closeModal}
				component={
					user.team?.name ||
					user.role === RoleEnumType.CONTRACTOR ||
					userList.length ? (
						<>
							<H2 className="flex m-b-half">Create payment request</H2>
							<ToggleGroupButton
								label="Select protocol"
								className="text-medium m-b-quarter"
								value={protocol}
								onChange={onChange}
								groupList={blockchainListShort}
							/>
							<ToggleGroupButton
								label="Select currency"
								className="text-medium m-t m-b-quarter"
								value={token}
								onChange={onChangeCurrency}
								groupList={getCurrenciesByProtocol(
									protocol as ProtocolEnumType,
									false,
									true
								)}
							/>

							<p className="text-medium m-t">
								Select one or more{' '}
								{user.role === RoleEnumType.COMPANY ? 'contractors' : 'clients'}
							</p>
							<Autocomplete
								value={users}
								multiple
								filterSelectedOptions
								options={userList}
								loading={isLoading}
								onChange={(event: any, newValue: ITeamRoleUser[]) => {
									setUsers(newValue)

									setError(null)
								}}
								getOptionLabel={(myUser) =>
									getCustomerRecipientTitle(
										myUser,
										myUser.team,
										user.role === RoleEnumType.COMPANY
											? RoleEnumType.CONTRACTOR
											: RoleEnumType.COMPANY
									)
								}
								isOptionEqualToValue={(current, value) => current.id === value.id}
								renderTags={(items: ITeamRoleUser[], getTagProps) =>
									items.map((myUser, index) => {
										const label = getCustomerRecipientTitle(
											myUser,
											myUser.team,
											user.role === RoleEnumType.COMPANY
												? RoleEnumType.CONTRACTOR
												: RoleEnumType.COMPANY
										)

										return (
											<Chip
												{...getTagProps({ index })}
												variant="outlined"
												label={label}
												className="tag-chip tag-chip_correct"
												deleteIcon={<Clear fontSize="small" />}
											/>
										)
									})
								}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={
											user.role === RoleEnumType.COMPANY
												? 'Contractors'
												: 'Clients'
										}
									/>
								)}
								renderOption={(props, option, { inputValue }) => {
									const label = getCustomerRecipientTitle(
										option,
										option.team,
										user.role === RoleEnumType.COMPANY
											? RoleEnumType.CONTRACTOR
											: RoleEnumType.COMPANY
									)
									const matches = match(label, inputValue, {
										insideWords: true,
									})
									const parts = parse(label, matches)

									return (
										<li {...props} key={option.id}>
											<div>
												{parts.map((part, index) => (
													<span
														key={part.text + index.toString()}
														className={`${part.highlight ? 'text-bold' : ''}`}
													>
														{part.text}
													</span>
												))}
											</div>
										</li>
									)
								}}
							/>
							<HelperText className={error ? '' : 'invisible'}>
								{error || 'invisible'}
							</HelperText>

							<div className="form__buttons m-t">
								<Button
									type="button"
									buttonStyle={`${SECONDARY} ${MEDIUM_H}`}
									variant={CONTAINED}
									onClick={closeModal}
								>
									Cancel
								</Button>
								<Button
									type="button"
									buttonStyle={`${PRIMARY} ${MEDIUM_H} ${MARGIN_LEFT}`}
									variant={CONTAINED}
									onClick={onSubmit}
									disabled={isInvoiceLoading}
								>
									Create
								</Button>
							</div>
						</>
					) : (
						<CompanyInformationRequired onClose={closeModal} />
					)
				}
			/>
		</>
	)
}

export default CreateInvoice
