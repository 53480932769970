import { keyStores } from 'near-api-js'

export default function getConfig(env: string) {
	switch (env) {
		case 'mainnet':
			return {
				networkId: 'mainnet',
				nodeUrl: 'https://rpc.mainnet.near.org',
				walletUrl: 'https://wallet.near.org',
				helperUrl: 'https://helper.mainnet.near.org',
				explorerUrl: 'https://explorer.mainnet.near.org',
				keyStore: new keyStores.BrowserLocalStorageKeyStore(),
			}
		case 'testnet':
			return {
				networkId: 'testnet',
				nodeUrl: 'https://rpc.testnet.near.org',
				walletUrl: 'https://wallet.testnet.near.org',
				helperUrl: 'https://helper.testnet.near.org',
				explorerUrl: 'https://explorer.testnet.near.org',
				keyStore: new keyStores.BrowserLocalStorageKeyStore(),
			}
		default:
			throw Error(
				`Unconfigured environment '${env}'. Can be configured in src/config.js.`
			)
	}
}
