import paths from 'navigation/paths'
import React from 'react'

import { LockOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router'

import { Button, H2 } from 'share'

import buttonStyles from 'constants/buttonSettings'

import './index.scss'

const { PRIMARY, BIG_W, BIG_H, CONTAINED, MARGIN_LEFT } = buttonStyles

interface ICompanyInformationRequired {
	onClose: () => void
}

const CompanyInformationRequired: React.FC<ICompanyInformationRequired> = ({
	onClose,
}) => {
	const navigate = useNavigate()

	const onAddCompanyInfo = () => {
		onClose()

		navigate(paths.companyProfile)
	}

	return (
		<div className="company-information-required flex flex-column justify-center align-items-center">
			<LockOutlined
				classes={{
					root: 'lock-icon',
				}}
				color="primary"
			/>

			<H2 className="m-t-half m-b-half">Company information required</H2>

			<p className="text-center text-medium m-b">
				In order to send invitations, you must first
				<br />
				add information about the company.
			</p>

			<Button
				type="button"
				buttonStyle={`${PRIMARY} ${BIG_H} ${BIG_W} ${MARGIN_LEFT}`}
				variant={CONTAINED}
				onClick={onAddCompanyInfo}
			>
				Add information
			</Button>
		</div>
	)
}

export default CompanyInformationRequired
