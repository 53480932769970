import { useState } from 'react'

interface IModal {
	(inizialState: boolean): [boolean, () => void]
}

const useSwitchState: IModal = (inizialState) => {
	const [isOpen, setIsOpen] = useState(inizialState)

	const handleModal = () => {
		setIsOpen((prev) => !prev)
	}

	return [isOpen, handleModal]
}

export default useSwitchState
